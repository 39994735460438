import React, { useState, useEffect, useContext, Fragment } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/table';
import { columns, tableSearch, renderTable } from './group.utils';
import { getGroupsReq } from '../../../api/group/groups';
import AddGroup from './modals/addGroup';
import DeleteGroup from './modals/deleteGroup';
import ShowGroup from './modals/showGroup';
import Head from '../../../components/head';
import { IconGroup } from '../../../assets/icons/icons-sidebar';

const Groups = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isAdd, setIsAdd] = useState();
  useEffect(() => {
    let isEdit, isDelete, isRead;

    isRead = permContext?.groupRead;
    isEdit = permContext?.groupUpdate;
    isDelete = permContext?.groupDelete;
    if (permContext && !isRead) window.location.href = '/dashboard';
    setIsAdd(isEdit);

    if (permContext) {
      /*Get data of the list*/
      getGroupsReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, setDeleteModal, setAddModal, setSelectedID, isEdit, isDelete),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/admin">Administration&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          {t('sidebar:groups')}
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setSelectedID={setSelectedID}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setSelectedID={setSelectedID}
          setShowModal={setShowModal}
          contain
          ContainHeader={() => ''}
        />
        {/*Add and edit group*/}
        {addModal && (
          <AddGroup addModal={addModal} setAddModal={setAddModal} selectedID={selectedID} />
        )}
        {/*Show group*/}
        {showModal && (
          <ShowGroup showModal={showModal} setShowModal={setShowModal} selectedID={selectedID} />
        )}
        {/*Delete group*/}
        {deleteModal && (
          <DeleteGroup
            selectedID={selectedID}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />
        )}
      </div>
    </div>
  );
};

export default Groups;
