import React, { useState } from 'react';
import moment from 'moment';
import { ReactComponent as IconDelete } from '../../assets/icons/component/ic_delete.svg';
import { ReactComponent as IconRefresh } from '../../assets/icons/component/ic_refresh.svg';
import IconLoading from '../loading/loading';
import { ArrowRight } from '../../assets/icons/icons-arrow';
import Button from '../button';
import ConfirmationCancelPayout from '../../modules/dashboard/client/modals/transactions/confirmationCancelPayout';
import { walletLineUpdateReq } from '../../api/client/walletline/walletline';

function TransactionCard({ transaction, userId, refreshData }) {

  const [cancelPayoutOpen, setCancelPayoutOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const refreshWalletLine = async (walletLineId) => {
    setLoading(true);
    walletLineUpdateReq(walletLineId)
      .then((res) => refreshData())
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="mt-3"><b>Informations</b></div>
      <hr />
      <div className="d-flex">
        <div className="mt-2">
          <div>
            <div><b>Date de l’opération</b></div>
            <div>{moment(transaction?.operation_date).format('DD/MM/YYYY')}</div>
          </div>
          <div>
            <div><b>Type</b></div>
            <div>{transaction?.type}{transaction?.object?.mcc_code === '6011' ? '(ATM)' : ''}</div>
          </div>
          <div>
            <div><b>Statut</b></div>
            <div>{transaction?.status}</div>
          </div>
        </div>
        <div className=" mt-2 ml-4">
          <div>
            <div><b>Libellé</b></div>
            <div>{transaction?.label}</div>
          </div>
          <div>
            <div><b>Montant</b></div>
            <div>{transaction?.type === 'DEBIT' ? '-' : ''}{transaction?.amount} €</div>
          </div>
        </div>
      </div>
      {transaction && transaction?.objectType === 'Transfer' && (
      <div>
        <div className="mt-3"><b>Transfer</b></div>
        <hr />
        <div className="d-flex">
          <div className="mt-2">
            <div>
              <div><b>Wallet source</b></div>
              <div>{transaction?.object?.wallet?.walletId} - {transaction?.object?.wallet?.trzWalletId}</div>
            </div>
            <div>
              <div><b>Nom source</b></div>
              <div>{transaction?.object?.wallet?.name}</div>
            </div>
          </div>
          <div className="mt-2 ml-4">
            <div>
              <div><b>Wallet bénéficiaire</b></div>
              <div>{transaction?.object?.beneficiaryWallet?.walletId} - {transaction?.object?.beneficiaryWallet?.trzWalletId}</div>
            </div>
            <div>
              <div><b>Nom bénéficiaire</b></div>
              <div>{transaction?.object?.beneficiaryWallet?.name}</div>
            </div>
          </div>
        </div>
      </div>
      )}
      {transaction && transaction?.objectType === 'Payout' && (
      <div>
        <div className="mt-3"><b>Payout</b></div>
        <hr />
        <div className="d-flex">
          <div className="mt-2">
            <div>
              <div><b>PayoutId</b></div>
              <div>{transaction?.object?.payoutId}</div>
            </div>
            <div>
              <div><b>Bénéficiaire Id</b></div>
              <div>{transaction?.object?.beneficiary?.beneficiaryId}</div>
            </div>
          </div>
          <div className="mt-2 ml-4">
            <div>
              <div><b>Trz PayoutId</b></div>
              <div>{transaction?.object?.trzPayoutId}</div>
            </div>
            <div>
              <div><b>Trz Beneficiary Id</b></div>
              <div>{transaction?.object?.beneficiary?.trzBeneficiaryId}</div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-2">
            <div><b>Nom Bénéficiaire</b></div>
            <div>{transaction?.object?.beneficiary?.name}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-2">
            <div><b>IBAN</b></div>
            <div>{transaction?.object?.beneficiary?.iban}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-2">
            <div><b>BIC</b></div>
            <div>{transaction?.object?.beneficiary?.bic}</div>
          </div>
        </div>
      </div>
      )}
      {transaction && transaction?.objectType === 'Payin' && (
      <div>
        <div className="mt-3">
          <b>Payin</b>
        </div>
        <hr />
        <div className="d-flex">
          <div className="mt-2 flex-fill">
            <div>
              <div><b>PayinId</b></div>
              <div>{transaction?.object?.payinId}</div>
            </div>
          </div>
          <div className="mt-2 ml-4 flex-fill">
            <div>
              <div><b>Trz PayinId</b></div>
              <div>{transaction?.object?.trzPayinId}</div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-2">
            <div>
              <div><b>IBAN Name</b></div>
              <div>{transaction?.object?.from?.ibanFullname}</div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-2">
            <div><b>IBAN</b></div>
            <div>{transaction?.object?.from?.iban}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="mt-2">
            <div><b>BIC</b></div>
            <div>{transaction?.object?.from?.ibanBic}</div>
          </div>
        </div>
      </div>
      )}
      {transaction && transaction?.objectType === 'CardPayment' && (
      <div>
        <div className="mt-3"><b>CardPayment</b></div>
        <hr />
        <div className="d-flex">
          <div className="mt-2">
            <div>
              <div><b>Montant Autorisé</b></div>
              <div>{transaction?.object?.amount_authorized} €</div>
            </div>
            <div className="mt-2">
              <div><b>Montant Settle</b></div>
              <div>{transaction?.object?.amount_settled} €</div>
            </div>
            <div className="mt-2">
              <div><b>Montant Reversed</b></div>
              <div>{transaction?.object?.amount_reversal} €</div>
            </div>
            <div className="mt-2">
              <div><b>Carte de Paiement</b></div>
              <div>{transaction?.object?.card?.cardId}</div>
            </div>
          </div>
          <div className=" mt-2 ml-4">
            <div className="mt-2">
              <div><b>Nom du Marchand</b></div>
              <div>{transaction?.object?.merchant?.merchant_name}</div>
            </div>
            <div className="mt-2">
              <div><b>Adresse</b></div>
              <div>{transaction?.object?.merchant?.merchant_address}<br/>{transaction?.object?.merchant?.merchant_city} - {transaction?.object?.merchant?.merchant_country}</div>
            </div>
            <div><div>&nbsp;</div><div>&nbsp;</div></div>
            <div className="mt-2">
              <div><b>Card PAN</b></div>
              <div>{transaction?.object?.card?.maskedPan}</div>
            </div>
          </div>
        </div>
      </div>
      )}

      <div className="mt-5">
        <div className="o-information-detail__actions">
          <b>Détail transaction
            <a href={`/dashboard/client/${userId}/wallets/${transaction.wallet_id}/line/${transaction.walletLineId}`} className="pl-2">
              Voir détail <ArrowRight />
            </a>
          </b>
        </div>
        <div>&nbsp;</div>
        <b>Actions</b>
        <hr />
        <div className="o-information-detail__actions">
        <Button btnType="outline" onClick={() => refreshWalletLine(transaction.walletLineId)}>
            {loading ? (
              <div className="d-flex mx-auto">
                <IconLoading />
              </div>
            ) : (
              <><IconRefresh/>Rafraichir</>
            )}
          </Button>
        {transaction && transaction?.objectType === 'Payout' && (
          <Button btnType="outline" onClick={() => setCancelPayoutOpen(true)}>
            <IconDelete/>Annulation
          </Button>
        )}
        </div>          
      </div>
      <ConfirmationCancelPayout
        cancelPayoutOpen={cancelPayoutOpen}
        setCancelPayoutOpen={setCancelPayoutOpen}
        userId={userId}
        payoutId={transaction?.object?.payoutId}
        refreshData={refreshData}
      />
    </div>
  );
}

export default TransactionCard;
