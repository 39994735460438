import { backendApiEndpoint } from '../../constants.js';
import { CheckAuth, instanceBackendApi } from '../../index';
import { toast } from 'react-toastify';

export async function walletLineDetailReq(transactionId) {
  try {
    const res = await instanceBackendApi.get(backendApiEndpoint.wallet_line_detail.replace('{{transactionId}}', transactionId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('WALLETLINE DETAIL - Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response?.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}

export async function walletLineUpdateReq(transactionId) {
  try {
    const res = await instanceBackendApi.put(backendApiEndpoint.wallet_line_update.replace('{{transactionId}}', transactionId));
    if (res.status === 200) {
      return res.data;
    } else {
      toast.error('WALLETLINE UPDATE - Une erreur est survenue!');
      return 'fail';
    }
  } catch (error) {
    toast.error('Fail! : Error :' + error.response?.data?.message);
    CheckAuth(error);
    return 'fail';
  }
}
