import React from 'react';

export const columns = [
  'ID',
  'Créditeur',
  'Reason Code',
  'Reject Reason Code',
  'SCTR Type',
  'SCTR ID',
];

export const tableSearch = (tableData, searchInput, setFilteredData) => {
  let result = tableData.filter((value) => {
    return (true);
  });
  setFilteredData(result);
};

export const renderTable = (res, t) => {
  let data = [];
  res.map((item) =>
    data.push({
      code: item.separecallsctrid,
      creditor: <a href={`/dashboard/client/${item.user_id}/operation/sctrrecall`}>User {item.user_id}</a>,
      reason_code: item.reason_code ? item.reason_code + ':' + t('sdd_errors:' + item.reason_code) : '-',
      reject_reason: item.negative_response_reason_code ? item.negative_response_reason_code + ':' + t('sdd_errors:' + item.negative_response_reason_code) : '-',
      sctr_type: item.sctr_type,
      sctr_tx_id: item.sctr_tx_id,
    }),
  );
  return data;
};
