import React, { useState, useEffect, useContext, Fragment } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';

import { useTranslation } from 'react-i18next';
import { columns, tableSearch, renderTable } from './permission.utils';
import { getPermissionsReq } from '../../../api/permission/permissions';

import Head from '../../../components/head';
import Table from '../../../components/table';
import AddPermission from './modals/addPermission';
import DeletePermission from './modals/deletePermission';
import ShowPermission from './modals/showPermission';
import { IconPermission } from '../../../assets/icons/icons-sidebar';

const Permission = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    let isEdit, isDelete;
    isEdit = permContext?.permissionUpdate;
    isDelete = permContext?.permissionDelete;
    setIsAdd(isEdit);

    if (permContext) {
      /*Get data of the list*/
      getPermissionsReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, setAddModal, setDeleteModal, setCurrentItem, isEdit, isDelete),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/admin">Administration&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          {t('sidebar:permission')}
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setCurrentItem={setCurrentItem}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setCurrentItem={setCurrentItem}
          contain
          ContainHeader={() => ''}
        />
      </div>
      {/*Add and edit permission modal*/}
      {addModal && (
        <AddPermission addModal={addModal} setAddModal={setAddModal} currentItem={currentItem} />
      )}
      {/*Show permission modal*/}
      {showModal && (
        <ShowPermission
          showModal={showModal}
          setShowModal={setShowModal}
          currentItem={currentItem}
        />
      )}
      {/*Delete permission modal*/}
      {deleteModal && (
        <DeletePermission
          currentItem={currentItem}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
    </div>
  );
};

export default Permission;
