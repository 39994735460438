import { useEffect, useState } from "react";

export const LogoOnlyOne = () => {
    const [colorScheme, setColorScheme] = useState(
        window.matchMedia("(prefers-color-scheme: light)").matches
            ? "light"
            : "dark"
    );
    const [logoSrc, setLogoSrc] = useState("");

    useEffect(() => {
        const colorSchemeListener = (e) => {
            const newColorScheme = e.matches ? "dark" : "light";
            setColorScheme(newColorScheme);
        };

        const mediaQuery = window.matchMedia("(prefers-color-scheme: light)");
        mediaQuery.addEventListener("change", colorSchemeListener);

        return () =>
            mediaQuery.removeEventListener("change", colorSchemeListener);
    }, []);

    useEffect(() => {
        import(
            `./${process.env.REACT_APP_MARKET}/logo/onlyone_${colorScheme}.svg` 
        )
            .then((module) => setLogoSrc(module.default))
            .catch((error) => console.error("Error loading logo:", error));
    }, [colorScheme]);

    return logoSrc ? <img src={logoSrc} alt="Logo" /> : null;
};





