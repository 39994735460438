import React, { useState, useEffect, useContext, Fragment } from 'react';
import PermissionsContext from '../../../contexts/permissionContext';
import { useTranslation } from 'react-i18next';
import { columns, renderTable, tableSearch } from './user.utils';
import { getUsersReq } from '../../../api/user/users';
import Head from '../../../components/head';
import Table from '../../../components/table';
import { IconUser } from '../../../assets/icons/icons-sidebar';
import AddUser from './modals/addUser';
import ShowUser from './modals/showUser';
import DeleteUser from './modals/deleteUser';

const User = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isAdd, setIsAdd] = useState();
  useEffect(() => {
    let isEdit, isDelete, isRead;

    isRead = permContext?.userRead;
    isEdit = permContext?.userUpdate;
    isDelete = permContext?.userDelete;
    if (permContext && !isRead) window.location.href = '/dashboard';
    setIsAdd(isEdit);
    if (permContext) {
      /*Get data of the list*/
      getUsersReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(
            renderTable(res, setAddModal, setDeleteModal, setSelectedID, isEdit, isDelete),
          );
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href="/dashboard/admin">Administration&nbsp;&nbsp;/&nbsp;&nbsp;</a>
          {t('sidebar:users')}
        </div>
      </Fragment>
    );
  }

  return (
    <div>
      <Head
        headTitle={headTitle()}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setAddModal={setAddModal}
        setSelectedID={setSelectedID}
        isAdd={isAdd}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
          setSelectedID={setSelectedID}
          contain
          ContainHeader={() => ''}
        />
      </div>
      {/*Add and edit user*/}
      {addModal && (
        <AddUser addModal={addModal} setAddModal={setAddModal} selectedID={selectedID} />
      )}
      {/*Show user*/}
      {showModal && (
        <ShowUser showModal={showModal} setShowModal={setShowModal} selectedID={selectedID} />
      )}
      {/*Delete user*/}
      {deleteModal && (
        <DeleteUser
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          selectedID={selectedID}
        />
      )}
    </div>
  );
};

export default User;
