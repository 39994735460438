import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../../../contexts/permissionContext';

import { columns, tableSearch, renderTable } from './sepasctrrecall.utils';
import { searchSepaSctrRecallReq } from '../../../../api/monitoring/monitoring';

import Head from '../../../../components/head';
import Table from '../../../../components/table';

const SepaSctrRecall = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [tableData, setTableData] = useState([]);
  const [dataStatus, setDataStatus] = useState('loading');
  const [searchInput, setSearchInput] = useState('');
    const [showModal, setShowModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (permContext) {
      /*Get data of the list*/
      searchSepaSctrRecallReq()
        .then((res) => {
          /*Render and set data of the list*/
          setTableData(renderTable(res, t));
        })
        .finally(() => setDataStatus('done'));
    }
  }, [permContext, t]);

  useEffect(() => {
    tableSearch(tableData, searchInput, setFilteredData);
  }, [searchInput, tableData]);

  return (
    <div>
      <Head
        headTitle="SEPA SCTR Recall"
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <div className="o-content">
        <Table
          columns={columns}
          tableData={searchInput ? filteredData : tableData}
          status={dataStatus}
          setShowModal={setShowModal}
        />
      </div>
    </div>
  );
};

export default SepaSctrRecall;
