import React, { useState, useContext, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import PermissionsContext from '../../../../../contexts/permissionContext.js';

// Requêtes
import { clientWalletListReq } from '../../../../../api/client/wallet.jsx';

// UI
import './preview_wallet.scss'
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx'
import PreviewWalletItem from './preview_wallet_item.jsx';

function PreviewWallet({ detailData,  ...props}) {

  const [userWallets, setUserWallets] = useState([]);
  const [loading, setLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);
  
  useEffect(() => {
    refreshData(detailData);
  }, [detailData]); 

  const refreshData = (detailData) => {
    setLoading(true);

    if (detailData?.userId) {
      clientWalletListReq(detailData?.userId)
      .then((res) => setUserWallets(res))
      .finally(() => setLoading(false));
    }
  };

  return (
    <BlocWithTitle title='Wallets' className="previewWallet__container">
      {userWallets?.slice(0, 3).map((wallet, index) => (
        <PreviewWalletItem key={wallet.walletId} wallet={wallet}/>
      ))}
      { userWallets.length > 3 && (
        <NavLink to={'/dashboard/client/' + detailData?.userId + '/wallets/list'}>Voir tous les wallets</NavLink>
      )}
    </BlocWithTitle>
  );
}

export default PreviewWallet;
